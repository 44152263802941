<template>
    <div class="mypage-modal-wrap">
        <div class="top_wrap">
            <div class="modal_title">{{ $t('bank_add') }}</div>
            <div class="close_btn_wrap">
                <button class="close_modal" @click="modalClose()"></button>
            </div>
        </div>
        <div class="middle_wrap">
            <el-input class="el_info_input pw" v-if="UserInfo.g_idx === 2"
                      :placeholder="$t('account_holder')" v-model="accountName"
                      @input="parentChange('accountName',accountName)"
                      @keypress.enter="save()"/>
            <div class="validation" v-if="validation.hasError('accountName') && UserInfo.g_idx === 2">
                {{ validation.firstError('accountName') }}
            </div>
            <el-input class="el_info_input pw"
                      :placeholder="$t('bank_name')" v-model="bankName"
                      @input="parentChange('bankName',bankName)"
                      @keypress.enter="save()"/>
            <div class="validation" v-if="validation.hasError('bankName')">
                {{ validation.firstError('bankName') }}
            </div>
            <el-input class="el_info_input pw" type="tel"
                      :placeholder="$t('account_number')" v-model="bankAccount"
                      @input="inputOnlyNumber('bankAccount',bankAccount)"
                      @keypress.enter="save()"/>
            <!--       비밀번호 체크 -->
            <div class="validation pw" v-if="validation.hasError('bankAccount')">
                {{ validation.firstError('bankAccount') }}
            </div>
            <div class="confirm_btn_wrap">
                <button class="btn save" @click="save()">{{ $t('save') }}</button>
                <button class="btn cancel" @click="modalClose()">{{ $t('cancel') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import memberValidator from "@/mixins/validators/memberValidator";

export default {
    name: "MemberWriteBankLayout",
    mixins: [alertMixins, memberValidator],
    components: {},
    inject: ['memberSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            ma_idx: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getBankList();
    },
    mounted() {
        this.initSetData();
        EventBus.$on('checkData', this.checkData);
        EventBus.$on('MemberWriteBankLayoutValidator', this.runValidator);
    },
    beforeDestroy() {
        EventBus.$off('checkData');
        EventBus.$off('MemberWriteBankLayoutValidator');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        modalClose() {
            this.$modal.hide('mypage-add-bank-modal')
        },
        initSetData() {
            if (!util.isEmpty(this.UserInfo.Account)) {
                this.bankName = this.UserInfo.Account[0].ma_bank;
                this.parentChange('bankName', this.bankName);
                this.bankAccount = this.UserInfo.Account[0].ma_account;
                this.parentChange('bankAccount', this.bankAccount);
                this.accountName = this.UserInfo.Account[0].ma_name;
                this.parentChange('accountName', this.accountName);
            }
            if (this.UserInfo.g_idx != 2) { // curator
                this.accountName = this.UserInfo.mb_name;
                this.parentChange('accountName', this.accountName);
            }
        },
        save() {
            if (this.UserInfo.g_idx != 2) { // curator
                this.accountName = this.UserInfo.mb_name;
                this.parentChange('accountName', this.accountName);
            }
            if(util.isEmpty(this.UserInfo.Account)) {
                EventBus.$emit('updateMember');
            }else{
                EventBus.$emit('apiLoading', true);
                let params = {ma_idx: this.ma_idx}
                this.$api.$member.deleteAccount(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        EventBus.$emit('apiLoading', false);
                        EventBus.$emit('updateMember');
                    } else {
                        EventBus.$emit('apiLoading', false);
                        return false;
                    }
                });
            }

        },
        runValidator() {
            let validatorArr = ['backName', 'bankAccount', 'accountName'];
            this.$validate(validatorArr)
        },
        checkData(type) {
            this.checkPwd = type
        },
        inputOnlyNumber(type, value) {
            var regex = /[^0-9]/g;				// 숫자가 아닌 문자열을 선택하는 정규식
            this[type] = value.replace(regex, "");
            this.parentChange(type, this[type]);
        },
        parentChange(type, value) {
            this[type] = value;
            this.memberSetData(type, value);
        },
        getBankList() {
            if(!util.isEmpty(this.UserInfo.Account)) {
                this.ma_idx = this.UserInfo.Account[0].ma_idx;
            }
        },
    },
    watch: {
    },
}
</script>

<style scoped>

</style>
